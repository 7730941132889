import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="about container">
      <div className="section">
        <PageContent className="content content--about" content={content} />
        <a className="button link" href="/img/DanAu-frontend-dev.pdf">
          View Resume
        </a>
        <div className="columns about__details">
          <div className="column">
            <h4 className="subhead">Development</h4>
            <p>
              HTML, CSS (SASS), JavaScript/ES6, React, GraphQL, jQuery, GSAP,
              Node.js, WebPack, Gulp/Grunt, Git, WordPress, Amazon Web Services,
              Shopify
            </p>
          </div>
          <div className="column">
            <h4 className="subhead">Design</h4>
            <p>Adobe XD, Adobe Photoshop, InDesign, Illustrator Sketch</p>
          </div>
          <div className="column">
            <h4 className="subhead">Education</h4>
            <p>UCLA Design Media Arts</p>
          </div>
          <div className="column">
            <h4 className="subhead">Social</h4>
            <ul>
              <li>
                <a className="link" href="https://www.linkedin.com/in/dausign/">
                  Linkedin
                </a>
              </li>
              <li>
                <a className="link" href="https://www.twitter.com/dausign">
                  Twitter
                </a>
              </li>
              <li>
                <a className="link" href="https://www.instagram.com/dausign">
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const AboutPage = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout location={location}>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
